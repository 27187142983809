import { motion } from "framer-motion";
import "./App.css";

function App() {
  return (
    <motion.svg
      width="360"
      viewBox="0 0 448 262"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M0 141H138L150 118L165 141H191L206 169L220 141H238L270 1L313 261L338 141H359L373 110L388 141H448"
        fill="none"
        strokeWidth="4"
        stroke="#8D2B2B"
        strokeLinecap="round"
        strokeLinejoin="round"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            pathLength: 0,
            pathOffset: 0,
          },
          visible: {
            pathOffset: 1,
            pathLength: 1,
            transition: {
              repeat: Infinity,
              duration: 2.5,
              ease: 'easeInOut',
            },
          },
        }}
      />
    </motion.svg>
  );
}

export default App;
